// eslint-disable-next-line import/no-extraneous-dependencies
import Perfume from "perfume.js";

// Esta 🛠 es para medir el performance de nuestra web [https://zizzamia.github.io/perfume]
// Solo se ejecutará en modo Stage y siempre que no sea un bot, para no entorpecer la renderización de servidor

if (!window.ENV?.isBot() && !window.ENV?.production()) {
  const perfume = new Perfume({
    firstContentfulPaint: true,
    firstInputDelay: true,
    dataConsumption: true,
    navigationTiming: true,
    // resourceTiming: true,
  });

  perfume.start("fibonacci");
}
